import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Radio, Checkbox } from 'Components';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { AddGroupValues } from 'Pages/Group/Group.types';

interface LMSSettingsProps {
	isTenant: boolean;
}
const LMSSettings: React.FC<LMSSettingsProps> = ({ isTenant }) => {
	const {
		touched,
		values,
		setFieldValue
	} = useFormikContext<AddGroupValues>();
	const { t } = useTranslation();

	return (
		<>
			{t('group.addGroup.components.LMSSettings.accessToModules')}
			<Checkbox.FormikField
				name="moduleEnabled"
				value="true"
				onChange={(e: unknown) => {
					if (!isTenant) {
						return;
					}
					const currentValue = (e as CheckboxChangeEvent).target
						.checked;
					if (currentValue) {
						if (!touched.viewAllCourses) {
							setFieldValue('viewAllCourses', true);
						}
					} else {
						setFieldValue('viewAllCourses', undefined);
					}
				}}
			>
				{t('group.addGroup.components.LMSSettings.lmsSettings')}
				{isTenant && values.moduleEnabled && (
					<Radio.Group.FormikField
						label={t(
							'group.addGroup.components.LMSSettings.viewAllCourses'
						)}
						name="viewAllCourses"
					>
						<Radio value={true}>{t('yes')}</Radio>
						<Radio value={false}>{t('no')}</Radio>
					</Radio.Group.FormikField>
				)}
			</Checkbox.FormikField>
		</>
	);
};

export default LMSSettings;
