import { APP_CONFIG } from 'app-config';
import { getUserRoleLabel } from 'app-util';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { CurrentUser } from 'Services/Api/Auth/Types';
import { SystemRolesListItem, SystemRoleType } from 'Services/Api/Roles/Types';
import {
	AddUserRequest,
	UpdateUserRequest,
	User,
	UserGroup
} from 'Services/Api/Users/Types';
import {
	AddUserValues,
	EditProfileValues,
	EditUserValues
} from '../User.types';

export const roleToOption = (
	value: SystemRolesListItem,
	valueKey?: keyof SystemRolesListItem
): EditUserValues['primarySystemRoleId'][0] => {
	const label = getUserRoleLabel(value.role);
	const key = String(valueKey ? value[valueKey] : value.id);
	return {
		key,
		value: key,
		label,
		data: value
	};
};

export const groupToOption = (group: UserGroup) => ({
	key: group.id,
	value: group.id,
	label: group.name,
	data: group
});

const isUser = (
	value: User | CurrentUser,
	isCurrentUser?: true
): value is User => isCurrentUser !== true;
export function getInitialValues(value: User): EditUserValues;
export function getInitialValues(
	value: CurrentUser,
	isCurrentUser: true
): EditProfileValues;
export function getInitialValues(
	value: User | CurrentUser,
	isCurrentUser?: true
) {
	const initialValues = {
		email: value.email,
		firstName: value.firstName,
		lastName: value.lastName,
		phoneNumber: value.phoneNumber,
		languageId: value.language?.id,
		timezoneId: value.timezone?.id,
		primarySystemRoleId: [
			roleToOption(value.primarySystemRole),
			...(value.secondarySystemRoles || []).map((secondarySystemRole) =>
				roleToOption(secondarySystemRole)
			)
		],
		cognitoLogin: value.cognitoLogin,
		groups: value.groups.map(groupToOption),
		doNotUpdateOnAutoFeeds: value?.doNotUpdateOnAutoFeeds,
		ownershipGroup: value.ownershipGroup
			? {
					key: value.ownershipGroup.id,
					value: value.ownershipGroup.id,
					label: value.ownershipGroup.name
			  }
			: undefined,
		moduleSettings: value.moduleSettings
			? {
					jobClassId: value.moduleSettings.jobClass?.peakV3Id,
					userRoleId: value.moduleSettings.userRole?.id
			  }
			: null
	};
	if (isUser(value, isCurrentUser)) {
		return { ...initialValues, peakV3Id: value.peakV3Id } as EditUserValues;
	}
	const ret: EditProfileValues = {
		...initialValues,
		notifications: value.notifications
			? {
					...value.notifications,
					time: moment(
						value.notifications.time,
						APP_CONFIG.TIME_FORMAT
					)
			  }
			: {
					time: null,
					day: null,
					getDeletionReport: false,
					getWeeklyReport: false
			  }
	};
	return ret;
}

export function getAddUserSubmitValues(
	values: AddUserValues
): AddUserRequest['input'] {
	const { primarySystemRoleId } = values;
	const primarySystemRole = primarySystemRoleId.find(
		(value) => value.data?.isPrimary
	) as AddUserValues['primarySystemRoleId'][0];

	const secondarySystemRoleIds = primarySystemRoleId.reduce<number[]>(
		(acc, value) => {
			if (value.data && !value.data.isPrimary && value.data.id !== 1) {
				acc.push(value.data.id);
			}
			return acc;
		},
		[]
	);

	return {
		cognitoLogin: values.cognitoLogin,
		email: values.email,
		firstName: values.firstName,
		lastName: values.lastName,
		languageId: values.languageId,
		timezoneId: values.timezoneId,
		groups:
			primarySystemRole.data.role !== SystemRoleType.Owner &&
			primarySystemRole.data.role !== SystemRoleType.SuperAdmin
				? values.groups?.map((group) => group.key)
				: [],
		primarySystemRoleId: primarySystemRole.data.id,
		ownershipGroup:
			primarySystemRole.data.role === SystemRoleType.Owner
				? values.ownershipGroup?.key
				: undefined,
		secondarySystemRoleIds: secondarySystemRoleIds || [],
		phoneNumber: values.phoneNumber || null,
		moduleSettings: !isEmpty(values.moduleSettings)
			? values.moduleSettings
			: null,
		doNotUpdateOnAutoFeeds: values.doNotUpdateOnAutoFeeds ?? false
	};
}

export function getEditUserSubmitValues(
	values: EditUserValues
): UpdateUserRequest['input'] {
	const { cognitoLogin, ...params } = getAddUserSubmitValues(values);

	return {
		...params,
		peakV3Id: values.peakV3Id
	};
}
