import { TableProps } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { Fx, Layout, Table } from 'Components';
import { createSortDescription } from 'Components/Table/createColumns';
import { useStores } from 'Hooks/useStore';
import { observer } from 'mobx-react-lite';
import { LayoutHolder, DownloadReportButton } from 'Pages/Dashboard/components';
import { useDashboardContext } from 'Pages/Dashboard/DashboardContext';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { OverviewGroupSummary } from 'Services/Api/Dashboard/Types';
import { ReportType } from 'Services/Api/Reports/Types';
import { SystemRoleType } from 'Services/Api/Roles/Types';
import { getIsPending } from 'Stores/util';
import { useColumns } from './columns';
import { usePermission } from 'Components/Permission/Permission.hooks';
import { PermissionCode } from 'Services/Api/Users/Types';

function GroupSummary() {
	const { t } = useTranslation();
	const { dashboardStore, authStore } = useStores();
	const { getHasPermission } = usePermission();
	const { filters } = useDashboardContext();
	const {
		overview: { groupSummary },
		status
	} = dashboardStore;

	useEffect(() => {
		dashboardStore.getOverviewGroupSummary(filters);
		//eslint-disable-next-line
	}, [filters]);

	useEffect(() => {
		if (
			[
				PermissionCode.ViewDashboardPhishing,
				PermissionCode.ViewDashboardElearning,
				PermissionCode.ViewDashboardSpotcheck
			].every((code) => {
				return !getHasPermission({ code }).permitted;
			})
		) {
			dashboardStore.changeOverviewGroupSummarySortParams();
		}
		//eslint-disable-next-line
	}, []);

	const handleTableChange: TableProps<OverviewGroupSummary>['onChange'] = (
		_,
		__,
		sorter
	) => {
		const sort = createSortDescription<OverviewGroupSummary>(
			sorter as SorterResult<OverviewGroupSummary>
		);

		dashboardStore.getOverviewGroupSummary({
			...groupSummary.params,
			sort
		});
	};

	return (
		<LayoutHolder>
			<Layout>
				<Layout.Heading>
					<Fx align="middle" justify="space-between">
						<h3>
							{authStore.currentUser?.primarySystemRole.role ===
							SystemRoleType.Owner
								? t('dashboard.propertySummary')
								: t('dashboard.groupSummary.title')}
						</h3>
						<DownloadReportButton
							reportType={ReportType.OverviewGroupSummary}
						/>
					</Fx>
				</Layout.Heading>
				<Table<OverviewGroupSummary>
					rowKey="groupName"
					dataSource={groupSummary.data}
					columns={useColumns({
						params: groupSummary.params
					})}
					pagination={false}
					onChange={handleTableChange}
					loading={getIsPending(status.getOverviewGroupSummary)}
				/>
			</Layout>
		</LayoutHolder>
	);
}

export default observer(GroupSummary);
