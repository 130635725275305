import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router';
import { ModalType, useModal } from 'Hooks/useModal';
import { useCallback, useRef } from 'react';

import {
	TOGGLE_USER_STATUS,
	DELETE_USER,
	RESET_PASSWORD
} from 'Services/Api/Users/Mutations';
import {
	ResetUserPasswordsRequest,
	ResetUserPasswordsResponse,
	ToggleUserStatusRequest
} from 'Services/Api/Users/Types';
import { UserActionModalName, UserActionConfirm } from '../../Users.constants';
import { UsersTableControlsProps } from './UsersTableControls';
import { getAppRoutes } from 'Pages/App/App.constants';
//import { MoveUsersModalValues } from '../MoveUsersModal/MoveUsersModal';
import { Notification } from 'Components';
import { useTranslation } from 'react-i18next';
import { handleForbiddenError } from '../../Users.helpers';
import { notifyUsersResetPasswordSuccess } from './UsersTableControls.helpers';

export function useUsersTableControls({
	selectedRowKeys: userIds,
	selectedUsers,
	onChange
}: UsersTableControlsProps) {
	const { t } = useTranslation();
	const { showModal, hideModal } = useModal();
	const [toggleUserStatus, toggleUserStatusResponse] = useMutation<
		void,
		ToggleUserStatusRequest
	>(TOGGLE_USER_STATUS);
	const history = useHistory();
	const [deleteUser, deleteUserResponse] = useMutation(DELETE_USER);
	const [resetPassword, resetPasswordResult] = useMutation<
		ResetUserPasswordsResponse,
		ResetUserPasswordsRequest
	>(RESET_PASSWORD);
	const action = useRef<UserActionConfirm | null>(null);
	const currentAction = action.current;

	const onAction = useCallback(
		(value?: ModalType['value']) => {
			showModal({
				value,
				name: UserActionModalName.Confirm
			});
		},
		[showModal]
	);

	const onActivate = useCallback(() => {
		action.current = UserActionConfirm.Activate;
		onAction();
	}, [onAction]);

	const onDisable = useCallback(() => {
		action.current = UserActionConfirm.Disable;
		onAction();
	}, [onAction]);

	const onDelete = useCallback(() => {
		action.current = UserActionConfirm.Delete;
		onAction();
	}, [onAction]);
/*
	const onMove = () => {
		const list = Object.values(selectedUsers);
		const value: MoveUsersModalValues = {
			userIds,
			usersCognitoLogins: list
				.map(({ cognitoLogin }) => `"${cognitoLogin}"`)
				.join(', '),
			initialValue: []
		};

		const first = list[0];
		if (list.length === 1) {
			value.userIdsName = `${first.firstName} ${first.lastName}`;
			if (first.groups.length === 1) {
				const firstGroup = first.groups[0];
				value.initialValue = [{ key: firstGroup.id, title: '' }];
			}
		}
		showModal({
			name: UserActionModalName.Move,
			value
		});
		// onAction(value);
	};
*/
	const onCancel = () => {
		hideModal();
		action.current = null;
	};
	const onAddUser = useCallback(() => {
		history.push(getAppRoutes().ADD_USER);
		// eslint-disable-next-line
	}, []);

	const onAddMultipleUsers = useCallback(() => {
		history.push(getAppRoutes().ADD_USERS);
		// eslint-disable-next-line
	}, []);
	const onConfirm = async () => {
		if (!currentAction) {
			return;
		}
		await CB_BY_USER_ACTION[currentAction]?.();
		onCancel();
		onChange();
	};
	const onResetPassword = () => {
		action.current = UserActionConfirm.ResetPassword;
		onAction();
	};

	const onRestore = () => {
		showModal({
			name: UserActionModalName.Restore,
			value: {
				userIds
			}
		});
	};
	const CB_BY_USER_ACTION = {
		[UserActionConfirm.Activate]: async () => {
			try {
				const response = await toggleUserStatus({
					variables: {
						input: {
							peakV3Ids: userIds,
							isActive: true
						}
					}
				});
				const messagePath = userIds.length > 1 ? 'multiple' : 'single';
				Notification.success({
					description: t(
						`users.components.activateModal.notification.success.${messagePath}`
					)
				});
				return response;
			} catch (e) {
				handleForbiddenError(
					e,
					'users.components.activateModal.notification.error'
				);
				return e;
			}
		},
		[UserActionConfirm.Disable]: async () => {
			try {
				const response = await toggleUserStatus({
					variables: {
						input: {
							peakV3Ids: userIds,
							isActive: false
						}
					}
				});
				const messagePath = userIds.length > 1 ? 'multiple' : 'single';
				Notification.success({
					description: t(
						`users.components.inactivateModal.notification.success.${messagePath}`
					)
				});
				return response;
			} catch (e) {
				handleForbiddenError(
					e,
					'users.components.inactivateModal.notification.error'
				);

				return e;
			}
		},
		[UserActionConfirm.Delete]: async () => {
			try {
				const response = await deleteUser({
					variables: {
						input: {
							peakV3Ids: userIds
						}
					}
				});
				const messagePath = userIds.length > 1 ? 'multiple' : 'single';
				Notification.success({
					description: t(
						`users.components.deleteModal.notification.success.${messagePath}`
					)
				});
				return response;
			} catch (e) {
				handleForbiddenError(
					e,
					'users.components.deleteModal.notification.error'
				);
				return e;
			}
		},
		[UserActionConfirm.ResetPassword]: async () => {
			try {
				const response = await resetPassword({
					variables: {
						input: {
							ids: userIds
						}
					}
				});
				notifyUsersResetPasswordSuccess(
					response.data?.resetPassword.totalCount ?? 0
				);
				return response;
			} catch (e) {
				handleForbiddenError(
					e,
					'users.components.resetUserPasswordsModal.notification.error'
				);
				return e;
			}
		}
	};

	const LOADING_BY_ACTION = {
		[UserActionConfirm.Activate]: toggleUserStatusResponse.loading,
		[UserActionConfirm.Disable]: toggleUserStatusResponse.loading,
		[UserActionConfirm.Delete]: deleteUserResponse.loading,
		[UserActionConfirm.ResetPassword]: resetPasswordResult.loading
	};

	return {
		currentAction: action.current,
		currentActionLoading: Boolean(
			action.current && LOADING_BY_ACTION[action.current]
		),
		onResetPassword,
		onActivate,
		onDisable,
		onDelete,
//		onMove,
		onCancel,
		onConfirm,
		onAddUser,
		onAddMultipleUsers,
		onRestore
	};
}
