import { stringifyUrl } from 'query-string';
import { System } from 'Services/Api/System/Types';
import { useStores } from './useStore';
import { useIsLMSModulesHidden } from 'Hooks';

export enum PagesWithDynamicUrl {
	Dashboard = 'dashboard',
	Modules = 'module'
}

export interface UsePeakV2UrlInput {
	page: PagesWithDynamicUrl;
	groupId?: number;
}

export function useCreatePeakV2URL(peakV2input: UsePeakV2UrlInput) {
	const { authStore } = useStores();
	const isLMSModulesHidden = useIsLMSModulesHidden();

	async function linkUserToPeakV2URL<PeakV2UrlParams>(
		system: System,
		params?: PeakV2UrlParams
	) {
		const { page } = peakV2input;

		if (!authStore.authSession?.isValid()) {
			await authStore.getAuthSession();
		}

		const tenantPostfix = authStore.isCurrentUserSuperAdmin
			? '/'
			: `/${authStore.currentUserPoolData?.postfix}/`;

		const url =
			system.url + tenantPostfix + process.env.REACT_APP_PEAK_V2_URL;

		const adaptedParams = params ?? {};

		const peakV2Url = stringifyUrl({
			url,
			query: {
				...adaptedParams,
				page,
				awsToken: authStore.authSession?.getIdToken().getJwtToken(),
				awsUserIsSuperAdmin:
					authStore.isCurrentUserSuperAdmin || undefined
			}
		});

		window.open(peakV2Url, '_blank');
	}

	return {
		isLMSModulesHidden: authStore.isCurrentUserOwner || isLMSModulesHidden,
		linkUserToPeakV2URL
	};
}
