import { Dispatch, SetStateAction, useEffect } from 'react';
import { debounce } from 'lodash';
import { useQuery } from '@apollo/client';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useStores } from 'Hooks/useStore';
import { SystemRoleType } from 'Services/Api/Roles/Types';
import { GROUP_HAS_PROPERTY } from 'Services/Api/Groups/Queries';
import {
	DashboardGroupFilterProps,
	Value
} from './filters/DashboardGroupFilter';
import {
	GroupHasPropertyRequest,
	GroupHasPropertyResponse
} from 'Services/Api/Groups/Types';
import { DashboardFiltersValues } from '../Dashboard.types';
import { DEBOUNCED_DELAY } from './DashboardFilters.constants';

export enum GroupsListType {
	Group,
	OwnershipGroup
}
export interface DashboardFiltersProps {
	initialValue?: Value;
	onChange: (values: DashboardFiltersValues) => void;
	onSetHasProperties?: Dispatch<SetStateAction<boolean>>;
	withInactive?: boolean;
}

function addFilters(withInactive?: boolean) {
	return withInactive
		? {
				filters: {
					withInactive: true
				}
		  }
		: {};
}

export function useDashboardFilters({
	initialValue,
	onSetHasProperties,
	onChange,
	withInactive
}: DashboardFiltersProps) {
	const { authStore } = useStores();
	const shouldCheckGroupHasProperty =
		authStore.currentUser?.primarySystemRole.role !== SystemRoleType.Owner;
	const result = useQuery<GroupHasPropertyResponse, GroupHasPropertyRequest>(
		GROUP_HAS_PROPERTY,
		{
			skip: !shouldCheckGroupHasProperty,
			notifyOnNetworkStatusChange: true,
			variables: initialValue
				? { id: initialValue[0].key, ...addFilters(withInactive) }
				: undefined
		}
	);

	const onGroupsChange: DashboardGroupFilterProps['onChange'] = async (
		value
	) => {
		const groupIds = value ?? [];

		const filterValues: DashboardFiltersValues = {
			id: groupIds[0]
		};

		if (shouldCheckGroupHasProperty) {
			const response = await result.refetch({
				id: filterValues.id,
				...addFilters(withInactive)
			});
			if (!response.data.groupHasProperty) {
				filterValues.isProperty = false;
			}
		}

		onChange(filterValues);
	};

	const onPropertyChange = debounce((event: CheckboxChangeEvent) => {
		const currentValue = event.target.checked;
		onChange({
			isProperty: currentValue
		});
	}, DEBOUNCED_DELAY);

	const groupHasProperty = Boolean(result.data?.groupHasProperty);
	const previousGroupHasProperty = Boolean(
		result.previousData?.groupHasProperty
	);
	const submitting = result.loading;
	const isPropertyCheckboxVisible =
		result.called &&
		(groupHasProperty || (submitting && previousGroupHasProperty));

	useEffect(() => {
		onSetHasProperties?.(isPropertyCheckboxVisible);
	}, [isPropertyCheckboxVisible, onSetHasProperties]);

	return {
		submitting,
		isPropertyCheckboxVisible,
		isPropertiesOnly: !shouldCheckGroupHasProperty,
		onGroupsChange,
		onPropertyChange
	};
}
