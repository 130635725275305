import { createSortDescription } from 'Components/Table/createColumns';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { axiosClient } from 'Services/Api/client';
import DASHBOARD_API from 'Services/Api/Dashboard/Api';
import {
	ElearningGroupSummary,
	ElearningGroupSummaryRequest,
	OverviewGroupSummary,
	PhishingActiveCampaign,
	PhishingActiveCampaignsRequest,
	PhishingTotalCampaignOverview,
	PhishingGroupSummary,
	PhishingGroupSummaryParams,
	SpotCheckOverviewResponse,
	SpotCheckOverviewParams,
	SpotCheckICIResponse,
	SpotCheckGroupSummaryParams,
	SpotCheckGroupSummaryResponse,
	MyResultsResponse,
	OverviewScores,
	OverviewGroupSummaryRequest,
	PhishingTotalCampaignScore,
	DashboardRequestBase,
	ElearningOverview,
	ElearningPathSummary,
	ElearningTotalScore,
	ElearningCourseActivity,
	HistoricalCompletedData,
	CampainSummaryData
} from 'Services/Api/Dashboard/Types';
import {
	Elearning,
	Overview,
	Phishing,
	SpotCheck
} from './DashboardStore.types';
import { Status } from 'app-types';
import { getSpotCheckSummaryOverviewData } from '../util';

class DashboardStore {
	// fetch statuses
	status = {
		exportDashboard: Status.Idle,
		getOverviewScores: Status.Idle,
		getOverviewGroupSummary: Status.Idle,

		getPhishingTotalCampaignOverview: Status.Idle,
		getPhishingTotalCampaignScore: Status.Idle,
		getPhishingActiveCampaigns: Status.Idle,
		getPhishingGroupSummary: Status.Idle,
		getPhishingCampaignSummary: Status.Idle,

		getElearningGroupSummary: Status.Idle,
		getElearningOverview: Status.Idle,
		getElearningPathSummary: Status.Idle,
		getElearningScore: Status.Idle,
		getElearningCourseActivity: Status.Idle,
		getElearningCompletedCourses: Status.Idle,

		getSpotCheckOverview: Status.Idle,
		getSpotCheckJobRole: Status.Idle,
		getSpotCheckGroupSummary: Status.Idle,
		getMyResults: Status.Idle
	};

	overview: Overview = {
		groupSummary: {
			data: [],
			params: {
				sort: createSortDescription({
					columnKey: 'indexComposite',
					order: 'ascend'
				})
			}
		}
	};

	phishing: Phishing = {
		activeCampaigns: {
			data: [],
			params: {
				sort: createSortDescription({
					columnKey: 'name',
					order: 'ascend'
				})
			}
		},
		groupSummary: {
			data: [],
			params: {
				sort: createSortDescription({
					columnKey: 'score',
					order: 'ascend'
				})
			}
		}
	};

	elearning: Elearning = {
		groupSummary: {
			data: [],
			params: {
				sort: createSortDescription({
					columnKey: 'score',
					order: 'ascend'
				})
			}
		}
	};

	spotCheck: SpotCheck = {
		overview: {
			data: null,
			params: {
				sort: 'spotCheck:current'
			}
		},
		jobRoles: {
			data: [],
			params: {
				sort: createSortDescription({
					columnKey: 'industryJobClassName',
					order: 'ascend'
				})
			}
		},
		groupSummary: {
			data: [],
			params: {
				sort: createSortDescription({
					columnKey: 'current',
					order: 'ascend'
				})
			}
		}
	};

	// my results data
	myResults?: MyResultsResponse;

	constructor() {
		makeObservable(this, {
			status: observable,
			overview: observable,
			phishing: observable,
			elearning: observable,
			spotCheck: observable
		});
	}

	@action
	async getOverviewScores(params: DashboardRequestBase = {}) {
		runInAction(() => {
			this.status.getOverviewScores = Status.Pending;
		});

		try {
			const response = await axiosClient.get<OverviewScores>(
				DASHBOARD_API.OVERVIEW_SCORES,
				{
					params
				}
			);

			runInAction(() => {
				this.overview.scores = response.data;
				this.status.getOverviewScores = Status.Success;
			});
		} catch (e) {
			// handle error
			runInAction(() => {
				this.status.getOverviewScores = Status.Failure;
			});

			throw e;
		}
	}

	@action
	async getPhishingTotalCampaignOverview(params: DashboardRequestBase = {}) {
		runInAction(() => {
			this.status.getPhishingTotalCampaignOverview = Status.Pending;
		});
		try {
			const response = await axiosClient.get<PhishingTotalCampaignOverview>(
				DASHBOARD_API.PHISHING_OVERVIEW,
				{ params }
			);

			runInAction(() => {
				this.phishing.overview = response.data;
				this.status.getPhishingTotalCampaignOverview = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getPhishingTotalCampaignOverview = Status.Failure;
			});
		}
	}

	@action
	async getPhishingTotalCampaignScore(params: DashboardRequestBase = {}) {
		runInAction(() => {
			this.status.getPhishingTotalCampaignScore = Status.Pending;
		});
		try {
			const response = await axiosClient.get<PhishingTotalCampaignScore>(
				DASHBOARD_API.PHISHING_SCORE,
				{
					params
				}
			);

			runInAction(() => {
				this.phishing.score = response.data;
				this.status.getPhishingTotalCampaignScore = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getPhishingTotalCampaignScore = Status.Failure;
			});
		}
	}

	@action
	async getOverviewGroupSummary(params?: OverviewGroupSummaryRequest) {
		runInAction(() => {
			this.status.getOverviewGroupSummary = Status.Pending;
			this.overview.groupSummary.params = {
				...this.overview.groupSummary.params,
				...params
			};
		});

		try {
			const response = await axiosClient.get<OverviewGroupSummary[]>(
				DASHBOARD_API.OVERVIEW_SUMMARY,
				{ params: this.overview.groupSummary.params }
			);

			runInAction(() => {
				this.overview.groupSummary.data = response.data;
				this.status.getOverviewGroupSummary = Status.Success;
			});
		} catch (e) {
			// handle error
			runInAction(() => {
				this.status.getOverviewGroupSummary = Status.Failure;
			});
		}
	}

	@action
	changeOverviewGroupSummarySortParams() {
		this.overview.groupSummary.params = {
			sort: createSortDescription({
				columnKey: 'groupName',
				order: 'ascend'
			})
		};
	}

	@action
	async getPhishingActiveCampaigns(
		params?: Partial<PhishingActiveCampaignsRequest>
	) {
		runInAction(() => {
			this.status.getPhishingActiveCampaigns = Status.Pending;
			this.phishing.activeCampaigns.params = {
				...this.phishing.activeCampaigns.params,
				...params
			};
		});

		try {
			const response = await axiosClient.get<PhishingActiveCampaign[]>(
				DASHBOARD_API.PHISHING_CAMPAIGNS,
				{ params: this.phishing.activeCampaigns.params }
			);

			runInAction(() => {
				this.phishing.activeCampaigns.data = response.data;
				this.status.getPhishingActiveCampaigns = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getPhishingActiveCampaigns = Status.Failure;
			});
		}
	}

	@action
	async getPhishingGroupSummary(
		params?: Partial<PhishingGroupSummaryParams>
	) {
		runInAction(() => {
			this.status.getPhishingGroupSummary = Status.Pending;
			this.phishing.groupSummary.params = {
				...this.phishing.groupSummary.params,
				...params
			};
		});

		try {
			const response = await axiosClient.get<PhishingGroupSummary[]>(
				DASHBOARD_API.PHISHING_GROUP_SUMMARY,
				{
					params: this.phishing.groupSummary.params
				}
			);
			runInAction(() => {
				this.phishing.groupSummary.data = response.data;
				this.status.getPhishingGroupSummary = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getPhishingGroupSummary = Status.Failure;
			});
		}
	}

	@action
	async getPhishingCampaignSummary(params: DashboardRequestBase = {}) {
		runInAction(() => {
			this.status.getPhishingCampaignSummary = Status.Pending;
		});
		try {
			const response = await axiosClient.get<CampainSummaryData>(
				DASHBOARD_API.PHISHING_CAMPAIGN_SUMMARY,
				{
					params
				}
			);
			runInAction(() => {
				this.phishing.campaignSummary = response.data;
				this.status.getPhishingCampaignSummary = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getPhishingCampaignSummary = Status.Failure;
			});
		}
	}

	@action
	async getElearningCourseActivity(params: DashboardRequestBase = {}) {
		runInAction(() => {
			this.status.getElearningCourseActivity = Status.Pending;
		});
		try {
			const response = await axiosClient.get<ElearningCourseActivity>(
				DASHBOARD_API.ELEARNING_COURSE_ACTIVITY,
				{
					params
				}
			);

			runInAction(() => {
				this.elearning.courseActivity = response.data;
				this.status.getElearningCourseActivity = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getElearningCourseActivity = Status.Failure;
			});
		}
	}

	@action
	async getElearningOverview(params: DashboardRequestBase = {}) {
		runInAction(() => {
			this.status.getElearningOverview = Status.Pending;
		});
		try {
			const response = await axiosClient.get<ElearningOverview>(
				DASHBOARD_API.ELEARNING_TOTAL_OVERVIEW,
				{
					params
				}
			);

			runInAction(() => {
				this.elearning.overview = response.data;
				this.status.getElearningOverview = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getElearningOverview = Status.Failure;
			});
		}
	}

	@action
	async getElearningScore(params: DashboardRequestBase = {}) {
		runInAction(() => {
			this.status.getElearningScore = Status.Pending;
		});
		try {
			const response = await axiosClient.get<ElearningTotalScore>(
				DASHBOARD_API.ELEARNING_TOTAL_SCORE,
				{
					params
				}
			);

			runInAction(() => {
				this.elearning.score = response.data;
				this.status.getElearningScore = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getElearningScore = Status.Failure;
			});
		}
	}

	@action
	async getElearningPathSummary(params: DashboardRequestBase = {}) {
		runInAction(() => {
			this.status.getElearningPathSummary = Status.Pending;
		});
		try {
			const response = await axiosClient.get<ElearningPathSummary[]>(
				DASHBOARD_API.ELEARNING_PATH_SUMMARY,
				{
					params
				}
			);

			runInAction(() => {
				this.elearning.pathSummary = response.data;
				this.status.getElearningPathSummary = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getElearningPathSummary = Status.Failure;
			});
		}
	}

	@action
	async getElearningGroupSummary(params: ElearningGroupSummaryRequest = {}) {
		runInAction(() => {
			this.status.getElearningGroupSummary = Status.Pending;
			this.elearning.groupSummary.params = {
				...this.elearning.groupSummary.params,
				...params
			};
		});

		try {
			const response = await axiosClient.get<ElearningGroupSummary[]>(
				DASHBOARD_API.ELEARNING_GROUP_SUMMARY,
				{
					params: this.elearning.groupSummary.params
				}
			);

			runInAction(() => {
				this.elearning.groupSummary.data = response.data;
				this.status.getElearningGroupSummary = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getElearningGroupSummary = Status.Failure;
			});
		}
	}

	@action
	async getElearningCompletedCourses(params: DashboardRequestBase = {}) {
		runInAction(() => {
			this.status.getElearningCompletedCourses = Status.Pending;
		});
		try {
			const response = await axiosClient.get<HistoricalCompletedData>(
				DASHBOARD_API.ELEARNING_COMPLETED_COURSES,
				{
					params
				}
			);

			runInAction(() => {
				this.elearning.completedCourses = response.data;
				this.status.getElearningCompletedCourses = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getElearningCompletedCourses = Status.Failure;
			});
		}
	}

	@action
	async getSpotCheckOverview(params?: SpotCheckOverviewParams) {
		runInAction(() => {
			this.status.getSpotCheckOverview = Status.Pending;
			this.spotCheck.overview.params = {
				...this.spotCheck.overview.params,
				...params
			};
		});

		try {
			const response = await axiosClient.get<SpotCheckOverviewResponse>(
				DASHBOARD_API.SPOT_CHECK_OVERVIEW,
				{
					params: this.spotCheck.overview.params
				}
			);

			runInAction(() => {
				this.spotCheck.overview.data = getSpotCheckSummaryOverviewData(
					response.data
				);
				this.status.getSpotCheckOverview = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getSpotCheckOverview = Status.Failure;
			});
		}
	}

	@action
	async getSpotCheckJobRoleSummary(params?: SpotCheckGroupSummaryParams) {
		runInAction(() => {
			this.status.getSpotCheckJobRole = Status.Pending;
			this.spotCheck.jobRoles.params = {
				...this.spotCheck.jobRoles.params,
				...params
			};
		});

		try {
			const response = await axiosClient.get<SpotCheckICIResponse>(
				DASHBOARD_API.SPOT_CHECK_JOB_ROLE,
				{
					params: this.spotCheck.jobRoles.params
				}
			);

			runInAction(() => {
				this.spotCheck.jobRoles.data = response.data;
				this.status.getSpotCheckJobRole = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getSpotCheckJobRole = Status.Failure;
			});
		}
	}

	@action
	async getSpotCheckGroupSummary(params?: SpotCheckGroupSummaryParams) {
		runInAction(() => {
			this.status.getSpotCheckGroupSummary = Status.Pending;
			this.spotCheck.groupSummary.params = {
				...this.spotCheck.groupSummary.params,
				...params
			};
		});
		try {
			const response = await axiosClient.get<SpotCheckGroupSummaryResponse>(
				DASHBOARD_API.SPOT_CHECK_SUMMARY,
				{
					params: this.spotCheck.groupSummary.params
				}
			);

			runInAction(() => {
				this.spotCheck.groupSummary.data = response.data;
				this.status.getSpotCheckGroupSummary = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getSpotCheckGroupSummary = Status.Failure;
			});
		}
	}

	@action
	async getMyResults() {
		runInAction(() => {
			this.status.getMyResults = Status.Pending;
		});
		try {
			const response = await axiosClient.get<MyResultsResponse>(
				DASHBOARD_API.MY_RESULTS
			);

			runInAction(() => {
				this.myResults = response.data;
				this.status.getMyResults = Status.Success;
			});
		} catch (e) {
			runInAction(() => {
				this.status.getMyResults = Status.Failure;
			});
		}
	}
}

export const dashboardStore = new DashboardStore();
export default DashboardStore;
