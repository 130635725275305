// @ts-nocheck
export function launchGainSightTag() {
	if (process.env.REACT_APP_GAINSIGHT_TAG) {
		const GAINSIGHT_URL = 'https://web-sdk.aptrinsic.com/api/aptrinsic.js';
		const i = 'aptrinsic';
		// eslint-disable-next-line
		(window[i] =
			window[i] ||
			function () {
				(window[i].q = window[i].q || []).push(
					window,
					document,
					GAINSIGHT_URL,
					process.env.REACT_APP_GAINSIGHT_TAG
				);
				// eslint-disable-next-line
			}),
			(window[i].p = process.env.REACT_APP_GAINSIGHT_TAG);
		const script = document.createElement('script');
		script.async = true;
		script.src =
			GAINSIGHT_URL + '?a=' + process.env.REACT_APP_GAINSIGHT_TAG;

		const firstScript = document.getElementsByTagName('script')[0];
		firstScript.parentNode.insertBefore(script, firstScript);
	}
}
export function launchTwak() {
	// eslint-disable-next-line
	var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
	(function(){
	// eslint-disable-next-line
	var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
	s1.async=true;
	s1.src='https://embed.tawk.to/61d3c0bf969d3d2539a070a8/1fohihrl9';
	s1.charset='UTF-8';
	s1.setAttribute('crossorigin','*');
	s0.parentNode.insertBefore(s1,s0);
	})();
}